import React, { useState } from "react"
import PreForm from "../../components/PreForm/PreForm"
import { navigate } from "gatsby"

export const PreContactFormContainer = ({
  questions,
  trattamenti,
  onSubmit,
  passedQuestion,
  passedTopic,
}) => {
  const [question, setQuestion] = useState(passedQuestion)
  const [topic, setTopic] = useState(passedTopic)

  return (
    <PreForm>
      <PreForm.Wrapper>
        <PreForm.Image
          data-aos="fade-right"
          img={require("../../images/doctor.png")}
        ></PreForm.Image>
        <PreForm.Form data-aos="fade-up">
          <PreForm.SubTitle>Chiedi al Chirurgo</PreForm.SubTitle>
          <PreForm.Title>
            Vuoi avere informazioni <br /> Su un trattamento o un intervento?
          </PreForm.Title>
          <PreForm.Label>Seleziona l'argomento</PreForm.Label>
          <PreForm.Auto
            freeSolo
            options={trattamenti}
            value={topic}
            onChange={(event, newInputValue) => {
              setTopic(newInputValue)
            }}
            getOptionLabel={option => option.trattamento}
            renderInput={params => (
              <PreForm.InputAuto {...params} label="Seleziona argomento" />
            )}
          />
          <PreForm.Label>Scegli la domanda</PreForm.Label>
          <PreForm.Auto
            freeSolo
            options={questions}
            value={question}
            onChange={(event, newInputValue) => {
              setQuestion(newInputValue)
            }}
            getOptionLabel={option => option.domanda}
            renderInput={params => (
              <PreForm.InputAuto {...params} label="Seleziona domanda" />
            )}
          />
          <PreForm.Button onClick={() => onSubmit(question, topic)}>
            Invia
          </PreForm.Button>
        </PreForm.Form>
      </PreForm.Wrapper>
    </PreForm>
  )
}
