import { Autocomplete as MaterialAuto } from "@material-ui/lab"
import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const SubTitle = styled.p`
  font-weight: 400;
  color: #337ab7;
`

export const Label = styled.p`
  font-weight: 600;
  margin-top: 10px;
  color: #001229;

  @media ${device.tablet} {
    font-size: 14px;
  }
`
export const Image = styled.div`
  background: url(${props => props.img}) center center/contain no-repeat;
  width: 100%;
  height: 700px;
  flex: 1;
  @media ${device.tablet} {
    display: none;
  }
`

export const Form = styled.div`
  padding: 50px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  @media ${device.tablet} {
    align-items: center;
  }
`
export const Title = styled.p`
  font-weight: 700;
  line-height: 1.1;
  color: #052957;
  font-size: 28px;
  text-align: left;

  @media ${device.tablet} {
    text-align: center;
    font-size: 21px;
    font-weight: 700;
  }
`

export const Wrapper = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: row;
`

export const Container = styled.div`
  padding-top: 70px;

  background-color: white;

  @media ${device.tablet} {
    text-align: center;
  }
`

export const Auto = styled(MaterialAuto)`
  @media ${device.tablet} {
    width: 230px;
  }
`

export const InputAuto = styled.input`
  border: none;
  outline: none;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: black;
  }
  @media ${device.tablet} {
    width: 170px;
  }
  width: 220px;
`

export const InputAutoWrapper = styled.div`
  border-radius: 12px;
  background-color: white;
  padding: 20px;
  width: 280px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-color: #2e5589;
  border-width: 1px;
  border-style: solid;
  @media ${device.tablet} {
    width: 100%;
  }
`

export const InputAutoWrapperError = styled(InputAutoWrapper)`
  border-color: #da2222ba;
  border-width: 2px;
  border-style: solid;
  ::placeholder {
    color: #da2222ba;
  }
`

export const Button = styled.div`
  cursor: pointer;
  border-radius: 12px;
  padding: 20px;
  border: 0;
  width: 280px;
  background: #264a79;
  color: white;
  font-weight: 700;

  margin-top: 20px;
  text-align: center;

  @media ${device.tablet} {
    width: 220px;
  }
`
