import React, { useEffect, useState } from "react"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { PreContactFormContainer } from "../containers/home/precontactform"
import styled from "styled-components"
import axios from "axios"
import {
  GET_DOMANDE,
  GET_RISPOSTE,
  GET_RISPOSTE_FAQ,
  GET_TRATTAMENTI,
} from "../constants/api"
import LoadingView from "../components/LoadingView/LoadingView"
import Layout from "../components/layout"
import scrollTo from "gatsby-plugin-smoothscroll"

const AnswersWrapper = styled.div`
  background: rgba(51, 122, 183, 0.11);
  width: 100%;
  justify-content: center;
  text-align: center;
`

const Answers = styled.div`
  max-width: 600px;
  justify-content: center;
  margin: auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 200px;
`

const Faqs = ({ location }) => {
  const [questions, setQuestions] = useState(null)
  const [trattamenti, setTrattamenti] = useState(null)
  const [answer, setAnswer] = useState(null)
  const [domanda, setDomanda] = useState("")

  const getPage = async () => {
    const { data } = await axios.get(GET_DOMANDE)
    const trattamenti = await axios.get(GET_TRATTAMENTI)
    setQuestions(data)
    setTrattamenti(trattamenti.data)
  }

  const fetchAnswers = async (domanda, trattamento) => {
    console.log("FETCHING")
    const { data } = await axios.get(
      GET_RISPOSTE_FAQ(domanda.id_sys, trattamento.id_sys)
    )
    console.log("FETCHED", domanda)
    setDomanda(domanda)
    setAnswer(data)
    scrollTo("#answers")
  }

  useEffect(() => {
    getPage()
    if (location?.state?.trattamento && location?.state?.question) {
      fetchAnswers(location?.state.question, location?.state.trattamento)
    }
  }, [])

  if (!questions) {
    return <LoadingView />
  }

  return (
    <Layout>
      <PreContactFormContainer
        questions={questions}
        trattamenti={trattamenti}
        passedQuestion={location?.state?.question}
        passedTopic={location?.state?.trattamento}
        onSubmit={(question, trattamento) => {
          fetchAnswers(question, trattamento)
        }}
      />
      <AnswersWrapper>
        <Answers id={"answers"}>
          <br />
          <h1>{domanda?.domanda || "Seleziona una domanda"}</h1>
          <span>
            {answer?.[0]?.risposta ||
              "Non è al momento disponibile una risposta alla tua domanda"}
          </span>
        </Answers>
      </AnswersWrapper>
    </Layout>
  )
}

export default Faqs
