import React from "react"
import {
  Title,
  Wrapper,
  Image,
  Container,
  Button,
  InputAutoWrapper,
  InputAutoWrapperError,
  InputAuto,
  Auto,
  Form,
  Label,
  SubTitle,
} from "./styles/prefo"

const PreForm = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

PreForm.Wrapper = function PreFormWrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>
}

PreForm.Image = function PreFormLabel({ children, ...restProps }) {
  return <Image {...restProps}></Image>
}

PreForm.Label = function PreFormLabel({ children, ...restProps }) {
  return <Label {...restProps}>{children}</Label>
}

PreForm.InputAuto = function PreFormInputAuto(props) {
  if (props.error) {
    return (
      <InputAutoWrapperError ref={props.InputProps.ref}>
        <InputAuto
          placeholder={props.label}
          type="text"
          {...props.inputProps}
        />
        <svg
          height="6"
          role="img"
          viewBox="0 0 10 6"
          width="10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L5.07223 5.1517L9.23083 1"></path>
        </svg>
      </InputAutoWrapperError>
    )
  }
  return (
    <InputAutoWrapper ref={props.InputProps.ref}>
      <InputAuto placeholder={props.label} type="text" {...props.inputProps} />
      <svg
        height="6"
        role="img"
        viewBox="0 0 10 6"
        width="10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L5.07223 5.1517L9.23083 1"></path>
      </svg>
    </InputAutoWrapper>
  )
}

PreForm.Title = function PreFormTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

PreForm.SubTitle = function PreFormSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>
}

PreForm.Form = function PreFormForm({ children, ...restProps }) {
  return <Form {...restProps}>{children}</Form>
}

PreForm.Auto = function PreFormAutoComplete({ ...restProps }) {
  return <Auto {...restProps} />
}

PreForm.Button = function PreFormButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>
}

export default PreForm
